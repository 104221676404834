import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Entrepreneurs = () => {
    return (
        <Layout>
            <Seo
                title="Vertragsnehmer - BIKESAFE"
                description="Alle Produkte von Bike Safe, einem erfahrenen Hersteller von Fahrradständern und Lieferant von Fahrradzubehör, werden mit Materialien von höchster Qualität und einem umfassenden handwerklichen Können hergestellt. Wir arbeiten mit durchgehenden Schweißnähten und hochmodernen Nachbearbeitungsverfahren. Alle Ständer verfügen standardmäßig über feuerverzinkte Oberflächen (gemäß BS 1461:1999) oder Oberflächen mit Polyester-Pulverbeschichtung. Alle unsere Produkte werden im Vereinigten Königreich von kompetenten Mechanikern und Handwerkern hergestellt."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Vertragsnehmer
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Materialien und handwerkliches Können</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                            Alle Produkte von Bike Safe, einem erfahrenen Hersteller von Fahrradständern und Lieferant von Fahrradzubehör, werden mit Materialien von höchster Qualität und einem umfassenden handwerklichen Können hergestellt. Wir arbeiten mit durchgehenden Schweißnähten und hochmodernen Nachbearbeitungsverfahren. Alle Ständer verfügen standardmäßig über feuerverzinkte Oberflächen (gemäß BS 1461:1999) oder Oberflächen mit Polyester-Pulverbeschichtung. Alle unsere Produkte werden im Vereinigten Königreich von kompetenten Mechanikern und Handwerkern hergestellt.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-5 mb-5 text-center">
                        <StaticImage placeholder="blurred" src="../images/entrepreneurs.jpg" className="shadow fluid rounded" />
                        <em className="flow-text">"Ihre Lieferzeiten waren kurz und die Zusammenarbeit mit Ihnen verlief reibungslos."
                            Lee Irvine, Irvine Utilities, London</em>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <h3>Bike Safe Produkte bieten folgende Vorteile:</h3>
                        <ul className="mt-3">
                            <li className="border p-2 shade-on-hover">Wartungsfrei: Unsere Produkte sind besonders strapazierfähig und langlebig</li>
                            <li className="border p-2 shade-on-hover">Einfach zu installieren auf allen Oberflächen</li>
                            <li className="border p-2 shade-on-hover">Ästhetik: Ansprechende Fahrradabstellplätze vor Gebäuden verschönern die Gebäudearchitektur und werden gern genutzt</li>
                            <li className="border p-2 shade-on-hover">Effiziente und ordentliche Fahrradaufbewahrung</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Entrepreneurs;